export const APP_CONFIGURATION = {
  PROXY_USER_GROUP: 'grpuguser-thesource-ios-homeoffice',
  SESSION_STORAGE_KEYS: {
    ALERTS: 'alerts',
    USER: 'currentUser',
    USER_STORES: 'user_stores',
    NEED_TO_KNOW_READ: 'need_to_know_read',
    DAILY_TASK_READ: 'daily_task_read'
  },
  LOCAL_STORAGE_KEYS: {
    STORENUMBER: 'StoreNo',
    STORE_CACHE: 'StoreCache',
    PROXY_STORE: 'proxystore',
    RETURN_URL: 'returnUrl',
    HIDDEN_ALERTS: 'hidden-alerts',
    SYSTEM_TYPE: 'SystemType'
  },
  HTTP_PARAMS: {
    DISTRICT: 'district',
    END_DATE: 'end_date',
    IDS: 'ids',
    KEY: 'key',
    PROXY_USER: 'proxy_user',
    REGION: 'region',
    STORE: 'store',
    STORENUMBER: 'StoreNo',
    START_DATE: 'start_date',
    RETURN_URL: 'returnUrl',
    AUTHENTICATED: 'authenticated'
  },
  HTTP_ERROR_RESPONSE_CODES: {
    UNAUTHENTICATED: 'UNAUTHENTICATED',
    UNAUTHORIZED_USER: 'UNAUTHORIZED_USER',
    UNAUTHORIZED_STORE_CONFIG: 'UNAUTHORIZED_STORE_CONFIG',
    UNAUTHORIZED_NETWORK: 'UNAUTHORIZED_NETWORK'
  },
  LOADING_DELAY_MS: 600,
  YEAR_MONTH_DAY_TIME: 'YYYY-MM-DD hh:mm:ss',
  YEAR_MONTH_DAY: 'YYYY-MM-DD',
  MONTH_DAY_YEAR: 'MM-DD-YYYY',
  MONTH_DAY_YEAR_TIME_ZONE: 'MM.DD.YY • h:mm a [EST]',
  MONTH_DAY_SHORTYEAR: 'MM.DD.YY',
  MONTH_NAME_SHORT_DAY: 'MMM DD',
  DAY: 'DD',
  SYSTEM_TYPES: {
    WORKSTATION: 'WKS',
    REGISTER: 'REG',
    TABLET: 'TAB'
  },
  PUSH_NOTIFICATION_VAPID_PUBLIC_KEY: 'BGisrbkn6_xu88Z9EM9moSyE86ImlMZvjMmEFxGALId18FX-XUZoSk3peXrxOroH93YatP_CTGYDNN168MNbU3w'
};
