import { Link } from './link';
import { LinkViewModel } from './link-view-model';
import { UrlPair } from './url-pair';

export class TileViewModel {
  image?: UrlPair;
  link?: LinkViewModel;

  constructor(link: Link, image: UrlPair) {
    this.link = new LinkViewModel(link);
    this.image = image;
  }
}
