export class SessionStorage {
  public static get(key: string): string {
    if (!sessionStorage || !sessionStorage.getItem) {
      return undefined;
    }

    return sessionStorage.getItem(key);
  }

  public static getObject(key: string): any {
    const cache = this.get(key);
    if (cache) {
      return JSON.parse(cache);
    }

    return cache;
  }

  public static set(key: string, value: string): void {
    if (!sessionStorage || !sessionStorage.getItem) {
      return;
    }

    sessionStorage.setItem(key, value);
  }

  public static setObject(key: string, obj: any): void {
    const strValue = JSON.stringify(obj);
    this.set(key, strValue);
  }

  public static remove(key: string): void {
    if (!sessionStorage || !sessionStorage.removeItem) {
      return;
    }

    sessionStorage.removeItem(key);
  }

  public static clear(): void {
    if (!sessionStorage || !sessionStorage.removeItem) {
      return;
    }

    sessionStorage.clear();
  }
}
