import { TakeAction } from './takeAction';
import { UserViewModel } from '@models';
import { TakeActionItemViewModel } from './takeActionItemViewModel';

export class TakeActionViewModel {
  date: Date;
  items: TakeActionItemViewModel[];
  filterOptions: any; // todo: set options
  user: UserViewModel;

  public get todoCount(): number {
    if (!this.items) {
      return 0;
    }

    return this.items.map(item => item.todoCount).reduce((a, b) => a + b);
  }

  constructor(
    takeActionItems: TakeAction[],
    date: Date,
    user: UserViewModel,
    readItems: number[]
  ) {
    this.user = user;
    this.date = date;

    this.items = takeActionItems.map((item: TakeAction) => {
      return new TakeActionItemViewModel(item, user, readItems);
    });
  }
}
