import { CalendarEvent } from './calendar-event';
import { TakeActionTask } from './takeActionTask';
import { TakeAction } from './takeAction';

export class CalendarEventExtendedProps {
  isDailyTask: boolean;
  todoCount: number;
  item: TakeAction | CalendarEvent;

  constructor(
    isDailyTask: boolean,
    tasks: TakeActionTask[],
    item?: TakeAction | CalendarEvent
  ) {
    this.isDailyTask = isDailyTask;
    this.item = item;

    if (!tasks) {
      this.todoCount = 0;
      return;
    }

    this.todoCount = tasks.filter((task: TakeActionTask) => {
      return !task.completed;
    }).length;
  }
}
