export enum FileExtensions {
  PDF = 'pdf',
  MP4 = 'mp4',
  MOV = 'mov',
  PNG = 'png',
  JPEG = 'jpeg',
  JPG = 'jpg',
  XLS = 'xls',
  XLSX = 'xlsx',
  PPT = 'ppt',
  PPTX = 'pptx',
  DOC = 'doc',
  DOCX = 'docx'
}
