import { Store } from './store';

export class StoreViewModel {
    storeNumber: string;
    storeName?: string;

    constructor(store: Store) {
        this.storeNumber = store.locationNumber;
        this.storeName = store.mallPlazaName;
    }
}

