import { DateUtility } from '@app/utilities';
import { Link } from './link';
import { SvgIcon } from './svg-icon';
import { File, FileType } from './file';
import { APP_CONFIGURATION } from '@app/config/app-configuration';
import { SearchableContent } from './searchable-content';

export enum Target {
  self = '_self',
  blank = '_blank'
}

export enum FileProtocols {
  asset = 'appasset',
  file = 'appfile'
}

export class LinkViewModel {
  target?: Target;
  item?: any; // todo: update this to all possible view models (NeedToKnowViewModel | AlertViewModel, etc.)
  url?: URL | string;
  icon?: SvgIcon;
  title?: string;

  constructor(link: Link, searchTerm?: string) {
    if (Object.keys(link).length === 0) {
      return null;
    }
    if (link.folder) {
      this.url = `/reference/folder/${link.folder.id}`;
      this.icon = new SvgIcon('alerts-document');
      this.title = link.folder.title;
      return;
    }
    if (link.file) {
      this.url = this.constructFileOrAssetUrl(
        FileType.file,
        link.file,
        searchTerm
      );
      this.icon = new SvgIcon('alerts-document');
      this.title = link.file.title;
    }
    if (link.remote_file) {
      this.target = Target.blank;
      this.url = new URL(link.remote_file.url);
      this.icon = new SvgIcon('alerts-document');
      this.title = link.remote_file.title;
      return;
    }
    if (link.need_to_know) {
      const ntkItem = link.need_to_know;
      const formattedStartDate = DateUtility.formatDate(
        DateUtility.getDate(
          ntkItem.start_date,
          APP_CONFIGURATION.YEAR_MONTH_DAY_TIME
        ),
        APP_CONFIGURATION.MONTH_DAY_YEAR
      );

      this.url = `/need-to-know/${formattedStartDate}/${ntkItem.id}`;
      this.icon = new SvgIcon('alerts-need-to-know');
      this.item = ntkItem;
      this.title = link.need_to_know.title;
      return;
    }
    if (link.take_action) {
      const takeActionItem = link.take_action;
      const formattedTakeActionDate = DateUtility.formatDate(
        DateUtility.getDate(
          takeActionItem.start_date,
          APP_CONFIGURATION.YEAR_MONTH_DAY_TIME
        ),
        APP_CONFIGURATION.MONTH_DAY_YEAR
      );
      this.url = `/daily-tasks/${formattedTakeActionDate}/${takeActionItem.id}`;
      this.icon = new SvgIcon('alerts-task');
      this.item = takeActionItem;
      this.title = link.take_action.title;
      return;
    }
    if (link.calendar_event) {
      const event = link.calendar_event;
      const formattedStartDate = DateUtility.formatDate(
        DateUtility.getDate(
          event.start_date,
          APP_CONFIGURATION.YEAR_MONTH_DAY_TIME
        ),
        APP_CONFIGURATION.MONTH_DAY_YEAR
      );
      const department = event.calendar.title;
      this.url = `/calendar/last/${formattedStartDate}?department=${department}`;
      this.title = event.title;
    }
    if (link.take_action_task) {
      this.url = `/daily-tasks`;
      this.icon = new SvgIcon('alerts-task');
      return;
    }
    if (link.web) {
      this.target = Target.blank;
      this.url = new URL(link.web);
      this.icon = new SvgIcon('alerts-urgent');
      this.title = link.web;
      return;
    }
    if (link.tab) {
      const tab = link.tab.id;

      if (tab === SearchableContent.home) {
        this.url = `/`;
        this.icon = new SvgIcon('alerts-urgent');
        this.title = 'Home';
        return;
      }
      if (tab === SearchableContent.dailyTasks) {
        this.url = `/daily-tasks`;
        this.icon = new SvgIcon('alerts-task');
        this.title = 'Daily Tasks';
        return;
      }
      if (tab === SearchableContent.calendar) {
        this.url = `/calendar`;
        this.icon = new SvgIcon('alerts-calendar');
        this.title = 'Calendar';
        return;
      }
      if (tab === SearchableContent.needToKnow) {
        this.url = `/need-to-know`;
        this.icon = new SvgIcon('alerts-need-to-know');
        this.title = 'Need to Know';
        return;
      }
      if (tab === SearchableContent.documents) {
        this.url = `/reference`;
        this.icon = new SvgIcon('alerts-document');
        this.title = 'Reference';
        return;
      }
      // todo: determine what we should do with links to reports, 404?
      if (tab === SearchableContent.reports) {
        this.url = `/reports`;
        this.icon = new SvgIcon('alerts-document');
        this.title = 'Reports';
        return;
      }
    }
  }

  private constructFileOrAssetUrl(
    type: FileType,
    file: File,
    searchTerm: string = null
  ): URL {
    const protocol =
      type === FileType.asset ? FileProtocols.asset : FileProtocols.file;

    if (file.filename && file.title) {
      let urlString = `${protocol}://${file.id}?title=${file.title}&name=${file.filename}`;
      if (file.mime_type) {
        urlString = `${urlString}&mime=${file.mime_type}`;
      }

      if (searchTerm) {
        urlString = `${urlString}&searchTerm=${searchTerm}`;
      }

      return new URL(urlString);
    } else {
      return new URL(`${protocol}://${file.id}`);
    }
  }
}
