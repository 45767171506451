import { ProxyRole } from './proxy-role';
import { Store } from './store';

export class ProxyStoreViewModel {
  buttonText?: string;
  locationNumber?: string;
  locationName?: string;
  districtName?: string;
  districtNumber?: string;
  regionName?: string;
  regionNumber?: string;
  displayName?: string;
  role?: ProxyRole;
  value?: string;

  constructor(store: Store | ProxyStoreViewModel, role?: ProxyRole) {
    if (this.isStore(store)) {
      this.locationName = store.mallPlazaName;
    } else {
      this.locationName = store.locationName;
    }
    this.locationNumber = Math.floor(+store.locationNumber).toString();
    this.districtName = store.districtName;
    this.districtNumber = Math.floor(+store.districtNumber).toString();
    this.regionName = store.regionName;
    this.regionNumber = Math.floor(+store.regionNumber).toString();

    if (!role) {
      this.role = ProxyRole.RegionalDirector;
    } else {
      this.role = role;
    }

    this.setAdditionalProperties(this.role);
  }

  // sets display value of list control
  public setAdditionalProperties(role: ProxyRole): void {
    if (role === ProxyRole.RegionalDirector) {
      this.buttonText = `Continue as Regional Director for ${this.regionName}`;
      this.displayName = `${this.regionName} - ${this.regionNumber}`.toUpperCase();
      this.value = this.regionNumber;
    } else if (role === ProxyRole.DistictManager) {
      this.buttonText = `Continue as Market Leader for ${this.districtName} District`;
      this.displayName = `${this.districtName} - ${this.districtNumber}`.toUpperCase();
      this.value = this.districtNumber;
    } else {
      this.buttonText = `Continue as Store Manager for ${this.locationNumber}`;
      this.displayName = `${this.locationNumber} - ${this.locationName}`.toUpperCase();
      this.value = this.locationNumber;
    }
  }

  public isStore(store: Store | ProxyStoreViewModel): store is Store {
    return (store as Store).mallPlazaName !== undefined;
  }
}
