import { NeedToKnowItemViewModel } from './needToKnowItemViewModel';
import { UserViewModel, NeedToKnow } from '@models';
import { DateUtility } from '@app/utilities';

export class NeedtoKnowViewModel {
  startDate: Date;
  endDate: Date;
  items: NeedToKnowItemViewModel[];
  filterOptions: any; // todo: set options
  user: UserViewModel;

  constructor(
    needToKnowItems: NeedToKnow[],
    startDate: Date,
    endDate: Date,
    user: UserViewModel,
    needToKnowRead: number[]
  ) {
    this.user = user;
    this.startDate = startDate;
    this.endDate = endDate;

    const mappedItems = needToKnowItems.map(item => {
      return new NeedToKnowItemViewModel(item, user, needToKnowRead);
    });

    const sortedByFeatured = mappedItems.sort(
      (a: NeedToKnowItemViewModel, b: NeedToKnowItemViewModel) => {
        return this.sortByFeatured(a, b);
      }
    );

    this.items = sortedByFeatured;
  }

  private sortByFeatured(
    a: NeedToKnowItemViewModel,
    b: NeedToKnowItemViewModel
  ): number {
    return a.isFeatured === b.isFeatured ? 0 : a.isFeatured ? -1 : 1;
  }
}
