import { DocumentFileType } from '../../data/models/document-file-type';
import { MicrosoftFileMimeTypes } from '../../data/models/microsoft-file-mime-types';

export class MimeTypeUtility {
  public static getDocumentFileTypeByMimeType(
    mimeType: string
  ): DocumentFileType {
    let documentFileType: DocumentFileType;

    switch ((mimeType && mimeType.toLowerCase()) || '') {
      case 'application/pdf':
        documentFileType = DocumentFileType.PDF;
        break;
      case 'video/mp4':
        documentFileType = DocumentFileType.Video;
        break;
      case 'video/quicktime':
        documentFileType = DocumentFileType.Video;
        break;
      case 'image/png':
        documentFileType = DocumentFileType.Image;
        break;
      case 'image/jpeg':
        documentFileType = DocumentFileType.Image;
        break;
      case 'image/jpg':
        documentFileType = DocumentFileType.Image;
        break;
      case MicrosoftFileMimeTypes.Excel:
        documentFileType = DocumentFileType.Microsoft_Doc;
        break;
      case MicrosoftFileMimeTypes.Excel_OpenXML:
        documentFileType = DocumentFileType.Microsoft_Doc;
        break;
      case MicrosoftFileMimeTypes.Excel_Binary:
        documentFileType = DocumentFileType.Microsoft_Doc;
        break;
      case MicrosoftFileMimeTypes.PowerPoint:
        documentFileType = DocumentFileType.Microsoft_Doc;
        break;
      case MicrosoftFileMimeTypes.PowerPoint_OpenXML:
        documentFileType = DocumentFileType.Microsoft_Doc;
        break;
      case MicrosoftFileMimeTypes.Word:
        documentFileType = DocumentFileType.Microsoft_Doc;
        break;
      case MicrosoftFileMimeTypes.Word_OpenXML:
        documentFileType = DocumentFileType.Microsoft_Doc;
        break;
      default:
        documentFileType = DocumentFileType.Other;
        break;
    }

    return documentFileType;
  }
}
