export class ColorUtility {
  private static BLACK = new Uint8ClampedArray([0, 0, 0, 0]);
  private static WHITE = new Uint8ClampedArray([255, 255, 255, 1]);

  public static black() {
    return this.BLACK;
  }

  public static white() {
    return this.WHITE;
  }

  public static convertHexToRGBA(hex: string, alpha: number = 1): string {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  }

  public static getRGBAColorFromImageXY(
    img: HTMLImageElement,
    x: number,
    y: number
  ): Uint8ClampedArray {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

    if (x > img.width || y > img.height) {
      return null;
    }

    canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
    return canvas.getContext('2d').getImageData(x, y, 1, 1).data;
  }

  public static rgba(rgba: number[]) {
    return `rgba(${rgba})`;
  }

  public static luminance(rgbaColor: Uint8ClampedArray): number {
    const rgba = rgbaColor.slice();

    for (let i = 0; i < 3; i++) {
      let rgb = rgba[i];
      rgb /= 255;
      rgb = rgb < 0.03928 ? rgb / 12.92 : Math.pow((rgb + 0.055) / 1.055, 2.4);
      rgba[i] = rgb;
    }

    return 0.2126 * rgba[0] + 0.7152 * rgba[1] + 0.0722 * rgba[2];
  }

  public static contrast(foreground: Uint8ClampedArray, background: Uint8ClampedArray): number {
    const l1 = this.luminance(foreground) + 0.05;
    const l2 = this.luminance(background) + 0.05;
    let ratio = l1 / l2;

    if (l2 > l1) {
      ratio = 1 / ratio;
    }

    return (ratio = Math.floor(ratio));
  }

  public static blackOverBackground(background: Uint8ClampedArray): number {
    return this.contrast(this.BLACK, background);
  }

  public static whiteOverBackground(background: Uint8ClampedArray): number {
    return this.contrast(this.WHITE, background);
  }

  public static inverseColor(rgba: number[], alpha: number = 1) {
    return [255 - rgba[0], 255 - rgba[1],	255 - rgba[2], alpha];
  }
}
