import { ProxyStoreViewModel } from './proxy-store';
import { ProxyRole } from './proxy-role';
export interface ProxyForm {
  district: string;
  role: ProxyRole;
  region: string;
  selection: ProxyStoreViewModel;
}

export enum ProxyFormFields {
  district = 'district',
  role = 'role',
  region = 'region',
  selection = 'selection',
  store = 'store'
}
