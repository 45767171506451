import { APP_CONFIGURATION } from '@app/index';
import { User } from './user';
import { ProxyUser } from './proxyUser';
import { ProxyRole } from './proxy-role';

export class UserViewModel {
  /**
   * The unique identifier for the user.
   */
  id: string;
  /**
   * The first name of the user.
   */
  first: string;
  /**
   * The last name of the user.
   */
  last: string;
  /**
   * The full name of the user.
   */
  fullName?: string;
  /**
   * A group the user is a member of.
   */
  groups: Array<string>;
  /**
   * Whether the user is a field leader. False for store users.
   */
  fieldLeader: boolean;
  /**
   * Whether the user is a proxy user. False for store users.
   */
  isProxyUser?: boolean;
  /**
   * Proxy User View Model
   */
  proxyUser?: ProxyUser;
  /**
   * Store
   */
  store?: string;
  /**
   * Either 'RD' for regional director, or 'DM' for district manager. Not provided if 'field_leader' is false.
   */
  role?: string;

  constructor(user: User) {
    this.id = user.id;
    this.first = user.first;
    this.last = user.last;
    this.fullName = `${user.first} ${user.last}`;
    this.groups = user.groups;
    this.fieldLeader = user.field_leader;
    this.role = user.role;
    this.isProxyUser = user.groups.includes(APP_CONFIGURATION.PROXY_USER_GROUP);
  }

  /**
   * Whether the user is a field leader or a proxy field leader.
   */
  public static isFieldLeader(user: UserViewModel): boolean {
    if (user.isProxyUser && user.proxyUser) {
      return user.proxyUser.fieldLeader;
    }

    return user.fieldLeader;
  }
}
