export class SvgIcon {
  /**
   * The Icon reference Id from the SVG Document
   */
  icon?: string;
  /**
   * The icon dimension class from the icon-dims.scss file
   */
  cssClass?: string;

  constructor(name: string) {
    this.icon = name;
    this.cssClass = `icon-${name}-dims`;
  }
}
