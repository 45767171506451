import { StoreGroup } from './storeGroup';

export class StoreGroupViewModel {
  /**
   * The id of the item.
   */
  id: number;
  /**
   * The status of the item.
   */
  status?: number;
  /**
   * The title of the item.
   */
  title: string;
  /**
   * A list of the store ids.
   */
  stores: string[];

  constructor(storeGroup: StoreGroup) {
    this.id = storeGroup.id;
    this.status = storeGroup.status;
    this.title = storeGroup.title;
    this.stores = storeGroup.store_ids.split(',');
  }
}
