import { DocumentFolderContent } from './documentFolderContents';
import { DocumentFolder } from './documentFolder';

export class DocumentFolderViewModel {
  id: number;
  title: string;
  parentFolderId?: number;
  subFolders: DocumentFolderViewModel[];
  contents: DocumentFolderContent[];
  referenceTreeFolders: any;

  constructor(documentFolder?: DocumentFolder) {
    if (!documentFolder) {
      return;
    }

    this.id = documentFolder.id;
    this.title = documentFolder.title;
    this.parentFolderId = documentFolder.parent_folder_id;

    this.subFolders = documentFolder.sub_folders.map(
      folder => new DocumentFolderViewModel(folder)
    );

    this.sortSubFolders();

    const files = documentFolder.files.map(
      file => new DocumentFolderContent(file)
    );
    const remoteFiles = documentFolder.remote_files.map(
      file => new DocumentFolderContent(file)
    );

    this.contents = [...files, ...remoteFiles];

    this.referenceTreeFolders =[...this.subFolders, ...documentFolder.remote_files];

    this.referenceTreeFolders.sort((a:any, b:any) => {
      const valueA = a.title.toLowerCase();
      const valueB = b.title.toLowerCase();

      return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    })
  }

  public sortSubFolders() {
    this.subFolders.sort((a, b) => {
      const valueA = a.title.toLowerCase();
      const valueB = b.title.toLowerCase();

      return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    });
  }
}
