export interface File {
  /**
   * Unique identifier.
   */
  id: number;
  /**
   * The title provided during upload.
   */
  title: string;
  /**
   * The filename used to load the contents of the file from the /files API.
   */
  filename: string;
  /**
   * The type of file.
   */
  mime_type: string;
  /**
   * The size of the file in bytes.
   */
  file_size: number;
  /**
   * False if the file is orphaned. Only provided when making requests to the Files endpoints.
   */
  has_parent_folder: boolean;
  /**
   * Number of times file has been modified.
   */
  revision: number;
  /**
   * The time the filet was uploaded or last updated.
   */
  upload_time?: string;
}

export enum FileType {
  asset = 'storage_asset',
  file = 'storage_file',
  report = 'storage_report'
}
