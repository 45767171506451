import { StoreDepartment } from './storeDepartment';
export class StoreDepartmentViewModel {
  id: number;
  title: string;
  fullTitle: string;
  color?: string;

  constructor(department: StoreDepartment) {
    this.id = department.id;
    this.title = department.title;
    this.fullTitle = department.full_title;
    this.color = department.color ? `#${department.color}` : '#000';
  }
}
