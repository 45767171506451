import { TaskViewModel } from './taskViewModel';
import { StoreDepartmentViewModel } from './storeDepartmentViewModel';
import { DateUtility } from '@app/utilities';
import { UserViewModel } from './userViewModel';
import { Asset } from './asset';
import { TakeAction } from './takeAction';
import { TakeActionTask } from './takeActionTask';
import { StoreGroup } from './storeGroup';
import { TaskGroupViewModel } from './taskGroupViewModel';
import { Activatable } from './activatable';
import { StoreGroupViewModel } from './storeGroupViewModel';
import { APP_CONFIGURATION } from '@app/config/app-configuration';

export class TakeActionItemViewModel extends Activatable {
  id: number;
  title: string;
  body: string;
  startDate: string;
  endDate: string;
  date: string;
  isNew: boolean;
  image: Asset;
  department: StoreDepartmentViewModel;
  storeGroups: StoreGroupViewModel[];
  isFieldLeadershipContent: boolean;
  showInWeekView: boolean;
  showinMonthView: boolean;
  tasksGroup: TaskGroupViewModel[];
  user: UserViewModel;
  priority: number;

  public get todoCount(): number {
    if (this.tasksGroup) {
      const array = this.tasksGroup.map(item => item.tasks);
      const flattenedArray = ([] as TaskViewModel[]).concat(...array);

      return flattenedArray.filter(task => !task.isCompleted).length;
    }
  }

  constructor(item: TakeAction, user: UserViewModel, readItems: number[]) {
    super(item.activate, item.deactivate);
    this.user = user;

    this.id = item.id;
    this.title = item.title;
    this.body = item.body;
    this.priority = item.priority;

    this.startDate = DateUtility.getDate(
      item.start_date,
      APP_CONFIGURATION.YEAR_MONTH_DAY_TIME
    ).toString();

    this.endDate = DateUtility.getDate(
      item.end_date,
      APP_CONFIGURATION.YEAR_MONTH_DAY_TIME
    ).toString();

    this.date = DateUtility.formatDate(
      new Date(this.startDate),
      APP_CONFIGURATION.MONTH_DAY_YEAR_TIME_ZONE
    );

    this.isNew = !readItems.includes(item.id);
    this.image = item.banner_image;
    this.department = new StoreDepartmentViewModel(item.department);
    this.storeGroups = item.limit_to_store_groups.map((group: StoreGroup) => {
      return new StoreGroupViewModel(group);
    });
    this.isFieldLeadershipContent = item.limit_to_field_leaders;

    this.showInWeekView = item.show_weekview;
    this.showinMonthView = item.show_monthview;

    const groupedTasks = this.groupTasks(item.tasks);
    this.tasksGroup = groupedTasks.map((tasks: TakeActionTask[]) => {
      return new TaskGroupViewModel(tasks, this.todoCount === 0);
    });
  }

  private sortTasks(tasks: TakeActionTask[]) {
    return tasks.sort((a, b) => a.order - b.order);
  }

  private groupTasks(tasks: TakeActionTask[]) {
    const sortedTasks = this.sortTasks(tasks);
    const groupedTasks = this.groupBy(sortedTasks);
    return groupedTasks;
  }

  private groupBy(initialArray: TakeActionTask[]) {
    const groupedMap = initialArray.reduce(
      (entryMap, e) =>
        entryMap.set(e.due_by.toLowerCase(), [
          ...(entryMap.get(e.due_by.toLowerCase()) || []),
          e
        ]),
      new Map()
    );
    return Array.from(groupedMap.values());
  }
}
