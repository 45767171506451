import { CalendarDateRange } from '@models';
import { APP_CONFIGURATION } from '@app/config/app-configuration';
import moment from 'moment';

export class DateUtility {
  public constructor(){}

  private static defaultFormattedDate = moment().format(
    APP_CONFIGURATION.MONTH_DAY_YEAR
  );

  // formats a date object
  public static formatDate(date?: Date, format?: string): string {
    if (!date) {
      return this.defaultFormattedDate;
    }
    if (!format) {
      return moment(date).format(APP_CONFIGURATION.MONTH_DAY_YEAR);
    }

    return moment(date).format(format);
  }

  // returns the current date in number format
  public static get currentDate(): () => number {
    return Date.now;
  }

  // returns the current date in format MM-DD-YYYY
  public static get currentFormattedDate(): string {
    return DateUtility.defaultFormattedDate;
  }

  public static getDate(dateString: string, format: string): Date {
    return moment(dateString, format).toDate();
  }

  public static addDays(date: Date, days: number) {
    return moment(date)
      .add(days, 'day')
      .toDate();
  }

  public static addWeeks(date: Date, weeks: number) {
    return moment(date)
      .add(weeks, 'week')
      .toDate();
  }

  public static addYears(date: Date, years: number) {
    return moment(date)
      .add(years, 'year')
      .toDate();
  }

  public static getMonth() {
    return moment().month();
  }

  public static getStartOfWeek(date?: Date): Date {
    return !date
      ? moment()
          .startOf('week')
          .toDate()
      : moment(date)
          .startOf('week')
          .toDate();
  }

  public static getEndOfWeek(date?: Date): Date {
    return !date
      ? moment()
          .endOf('week')
          .toDate()
      : moment(date)
          .endOf('week')
          .toDate();
  }

  public static buildDateRangeString(startDate: Date, endDate: Date) {
    const formattedStartDate = this.formatDate(
      startDate,
      APP_CONFIGURATION.MONTH_NAME_SHORT_DAY
    );

    let formattedEndDate;
    if (moment(startDate).isSame(endDate, 'month')) {
      formattedEndDate = this.formatDate(endDate, APP_CONFIGURATION.DAY);
    } else {
      formattedEndDate = this.formatDate(
        endDate,
        APP_CONFIGURATION.MONTH_NAME_SHORT_DAY
      );
    }

    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  public static isSameDay(date: Date, comparisonDate: Date): boolean {
    return moment(date).isSame(moment(comparisonDate), 'day');
  }

  public static isSameWeek(date: Date, comparisonDate: Date): boolean {
    return moment(date).isSame(moment(comparisonDate), 'week');
  }

  public static isBetween(
    date: Date,
    start: Date | moment.Moment,
    end: Date | moment.Moment,
    inclusitivity: '[]' | '[)' | '(]' | '()' = '[]'
  ): boolean {
    return moment(date).isBetween(start, end, null, inclusitivity);
  }

  public static isAfter(
    date: Date,
    comparisonDate: Date,
    inclusive: boolean = true
  ): boolean {
    if (inclusive) {
      return moment(date).isSameOrAfter(comparisonDate);
    }

    return moment(date).isAfter(comparisonDate);
  }

  public static isBefore(
    date: Date,
    comparisonDate: Date,
    inclusive: boolean = true
  ): boolean {
    if (inclusive) {
      return moment(date).isSameOrBefore(comparisonDate);
    }

    return moment(date).isBefore(comparisonDate);
  }

  public static combineContiguousRanges<TDateRange extends CalendarDateRange>(
    ranges: TDateRange[]
  ): TDateRange[] {
    const contiguousEvents: TDateRange[] = [];

    ranges.forEach((range, index) => {
      if (index === 0) {
        contiguousEvents.push(range);
      }

      const overlappingEvent = contiguousEvents.find(
        x =>
          this.isBetween(range.start, x.start, x.end) ||
          this.isBetween(range.end, x.start, x.end)
      );

      if (overlappingEvent) {
        // if the comparison range starts before the current range, extend the current range's start date
        if (this.isAfter(overlappingEvent.start, range.start)) {
          overlappingEvent.start = range.start;
        }

        // if the comparison range ends before the current range, extend the current range's end date
        if (this.isBefore(overlappingEvent.end, range.end)) {
          overlappingEvent.end = range.end;
        }
      } else {
        contiguousEvents.push(range);
      }
    });

    return contiguousEvents;
  }

  public static coerceToUtc(date: Date): Date {
    const startDay = date.getUTCDate();
    const startMonth = date.getUTCMonth();
    const startYear = date.getUTCFullYear();

    return new Date(startYear, startMonth, startDay);
  }

  public static isWithinRange(
    range: CalendarDateRange,
    otherRange: CalendarDateRange,
    inclusivity: '[]' | '[)' | '(]' | '()' = '[]'
  ) {
    return (
      this.isBetween(
        range.start,
        otherRange.start,
        otherRange.end,
        inclusivity
      ) ||
      this.isBetween(range.end, otherRange.start, otherRange.end, inclusivity)
    );
  }

  public static rangesOverlap(
    firstStart: Date,
    firstEnd: Date,
    secondStart: Date,
    secondEnd: Date
  ) {
    if (firstStart <= secondStart && secondStart <= firstEnd) {
      return true;
    } // second starts in first
    if (firstStart <= secondEnd && secondEnd <= firstEnd) {
      return true;
    } // second ends in first
    if (secondStart < firstStart && firstEnd < secondEnd) {
      return true;
    } // first in second
    return false;
  }
}
