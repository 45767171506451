import { TakeActionTask } from './takeActionTask';
import { TaskViewModel } from './taskViewModel';

export class TaskGroupViewModel {
  dueBy: string;
  tasks: TaskViewModel[];

  constructor(tasks: TakeActionTask[], allTasksComplete: boolean) {
    if (tasks && tasks.length > 0) {
      this.dueBy = tasks[0].due_by;
    } else {
      this.dueBy = '';
    }
    const sortedTasks = this.sortTasks(tasks);
    this.tasks = sortedTasks.map(task => {
      return new TaskViewModel(task, allTasksComplete);
    });
  }

  private sortTasks(tasks: TakeActionTask[]) {
    return tasks.sort((a, b) => a.order - b.order);
  }
}
