import { CalendarDateRange } from './calendar-date-range';
import { CalendarEventViewModel } from './calendar-event-view-model';

export class CalendarViewModel {
  loadedDateRange: CalendarDateRange;
  items: CalendarEventViewModel[];
  dailyTasksIncluded: boolean;
  calendarEventsIncluded: boolean;

  constructor(
    startDate: Date,
    endDate: Date,
    items: CalendarEventViewModel[],
    calendarEventsIncluded: boolean,
    dailyTasksIncluded: boolean
  ) {
    this.loadedDateRange = {
      start: startDate,
      end: endDate
    };

    this.items = items;

    this.calendarEventsIncluded = calendarEventsIncluded;
    this.dailyTasksIncluded = dailyTasksIncluded;
  }
}
