import { DateUtility } from '@app/utilities';
import { APP_CONFIGURATION } from '@app/config/app-configuration';

export class Activatable {
  activate: Date;
  deactivate: Date;

  constructor(activate: string, deactivate: string) {
    this.activate = DateUtility.getDate(
      activate,
      APP_CONFIGURATION.YEAR_MONTH_DAY_TIME
    );
    this.deactivate = DateUtility.getDate(
      deactivate,
      APP_CONFIGURATION.YEAR_MONTH_DAY_TIME
    );
  }

  public isActive(now: Date) {
    if (this.activate > now) {
      return false;
    }

    if (this.deactivate <= now) {
      return false;
    }
    return true;
  }

  public filteredByActive(now: Date = new Date(), items: Array<any>) {
    return items.filter(item => {
      if (item instanceof Activatable) {
        return item.isActive(now);
      }
      return true;
    });
  }
}
