import { Activatable } from './activatable';
import { UserViewModel } from './userViewModel';
import { Asset } from './asset';
import { NeedToKnow } from '@models';
import { DateUtility } from '@app/utilities';
import { StoreGroupViewModel } from './storeGroupViewModel';
import { APP_CONFIGURATION } from '@app/config/app-configuration';

export class NeedToKnowItemViewModel extends Activatable {
  id: number;
  title: string;
  body: string;
  isFeatured: boolean;
  startDate: string;
  endDate: string;
  date: string;
  image: Asset;
  storeGroups: StoreGroupViewModel[];
  isFieldLeadershipContent: boolean;
  user: UserViewModel;
  isNew: boolean;
  priority: number;

  constructor(item: NeedToKnow, user: UserViewModel, needToKnowRead: number[]) {
    super(item.activate, item.deactivate);
    this.user = user;

    this.id = item.id;
    this.title = item.title;
    this.body = item.body;
    this.isFeatured = item.featured;
    this.priority = item.priority;

    this.startDate = DateUtility.getDate(
      item.start_date,
      APP_CONFIGURATION.YEAR_MONTH_DAY_TIME
    ).toString();

    this.endDate = DateUtility.getDate(
      item.end_date,
      APP_CONFIGURATION.YEAR_MONTH_DAY_TIME
    ).toString();

    this.date = DateUtility.formatDate(
      new Date(this.startDate),
      APP_CONFIGURATION.MONTH_DAY_YEAR_TIME_ZONE
    );

    this.storeGroups = item.limit_to_store_groups.map(x => {
      return new StoreGroupViewModel(x);
    });
    this.isFieldLeadershipContent = item.limit_to_field_leaders;
    this.isNew = !needToKnowRead.includes(item.id);

    const formattedStartDate = DateUtility.formatDate(
      new Date(this.startDate),
      APP_CONFIGURATION.MONTH_DAY_SHORTYEAR
    );
    const formattedEndDate = DateUtility.formatDate(
      new Date(this.endDate),
      APP_CONFIGURATION.MONTH_DAY_SHORTYEAR
    );

    this.date = `${formattedStartDate} - ${formattedEndDate}`;
    this.image = item.banner_image;
  }
}
