export class LocalStorage {
  public static get(key: string): string {
    if (!localStorage || !localStorage.getItem) {
      return undefined;
    }

    return localStorage.getItem(key);
  }

  public static set(key: string, value: string): void {
    if (!localStorage || !localStorage.getItem) {
      return;
    }

    localStorage.setItem(key, value);
  }

  public static remove(key: string): void {
    if (!localStorage || !localStorage.removeItem) {
      return;
    }

    localStorage.removeItem(key);
  }

  public static clear(): void {
    if (!localStorage || !localStorage.removeItem) {
      return;
    }

    localStorage.clear();
  }
}
