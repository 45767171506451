import { ProxyStoreViewModel } from './proxy-store';
import { ProxyRole } from './proxy-role';

export class ProxyUser {
  identity?: string;
  fieldLeader: boolean;
  district?: string;
  region?: string;
  role?: ProxyRole;
  roleShort?: string;
  store?: string;

  constructor(store: ProxyStoreViewModel) {
    if (store) {
      this.role = store.role;
      if (store.role === ProxyRole.RegionalDirector) {
        this.region = store.regionNumber;
        this.identity = store.regionName;
        this.roleShort = 'RD';
        this.fieldLeader = true;
        return;
      }
      if (store.role === ProxyRole.DistictManager) {
        this.district = store.districtNumber;
        this.identity = store.districtName;
        this.roleShort = 'DM';
        this.fieldLeader = true;
        return;
      }
      if (store.role === ProxyRole.Store) {
        this.store = store.locationNumber;
        this.identity = store.locationName;
        this.roleShort = 'SM';
        this.fieldLeader = false;
      }
    }
  }
}
