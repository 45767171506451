import { DocumentFileType } from './document-file-type';
import { MicrosoftFileMimeTypes } from './microsoft-file-mime-types';
import { RemoteFile } from './remoteFile';
import { SvgIcon } from './svg-icon';
import { File } from './file';
import { ReferenceContentType } from './referenceContentType';
import { MimeTypeUtility } from '@app/utilities/mime-utility';

export class DocumentFolderContent {
  id: number;
  title: string;
  name?: string;
  type: ReferenceContentType;
  mimeType?: string;
  documentType?: DocumentFileType;
  url?: string;
  icon: SvgIcon;

  constructor(content: File | RemoteFile) {
    this.id = content.id;
    this.title = content.title;

    if ('url' in content) {
      content = content as RemoteFile;
      this.type = ReferenceContentType.remoteFile;
      this.icon = new SvgIcon('remote-file');
      this.url = content.url;
    } else {
      this.type = ReferenceContentType.file;
      this.name = content.filename;
      this.mimeType = content.mime_type;
      this.documentType = MimeTypeUtility.getDocumentFileTypeByMimeType(
        this.mimeType
      );
      this.icon = this.setFileIcon();
    }
  }

  // TODO: Move this logic into an icon utility/service and refactor
  private setFileIcon(): SvgIcon {
    if (this.documentType === DocumentFileType.PDF) {
      return new SvgIcon('file-icon-pdf');
    } else if (this.documentType === DocumentFileType.Video) {
      return new SvgIcon('file-icon-video');
    } else if (this.documentType === DocumentFileType.Image) {
      return new SvgIcon('file-icon-image');
    } else if (this.documentType === DocumentFileType.Other) {
      this.documentType = DocumentFileType.Microsoft_Doc;
    }

    const mime = this.mimeType.toLowerCase();

    if (mime.startsWith('video')) {
      this.documentType = DocumentFileType.Video;
      return new SvgIcon('file-icon-video');
    } else if (mime.startsWith('image')) {
      this.documentType = DocumentFileType.Image;
      return new SvgIcon('file-icon-image');
    }

    // Microsoft Mime Types
    switch (mime) {
      case MicrosoftFileMimeTypes.Excel: {
        return new SvgIcon('file-icon-excel');
      }
      case MicrosoftFileMimeTypes.Excel_OpenXML: {
        return new SvgIcon('file-icon-excel');
      }
      case MicrosoftFileMimeTypes.Excel_Binary: {
        return new SvgIcon('file-icon-excel');
      }
      case MicrosoftFileMimeTypes.Word: {
        return new SvgIcon('file-icon-word');
      }
      case MicrosoftFileMimeTypes.Word_OpenXML: {
        return new SvgIcon('file-icon-word');
      }
      case MicrosoftFileMimeTypes.PowerPoint: {
        return new SvgIcon('file-icon-powerpoint');
      }
      case MicrosoftFileMimeTypes.PowerPoint_OpenXML: {
        return new SvgIcon('file-icon-powerpoint');
      }
      default: {
        return new SvgIcon('file-icon-document');
      }
    }
  }
}
