import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';

const HTTP_OPTIONS = {
  withCredentials: true,
  params: new HttpParams(),
  headers: new HttpHeaders({
    'Cache-Control': 'no-cache'
  })
};

const BASE_API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    private httpClient: HttpClient,
    private loaderService: LoaderService
  ) {}

  get<T>(
    url: string,
    params?: HttpParams,
    spin: boolean = true
  ): Observable<T> {
    if (params) {
      HTTP_OPTIONS.params = params;
    }

    if (spin) {
      this.loaderService.showLoader();
    }

    const response = this.httpClient
      .get<T>(BASE_API_URL + url, HTTP_OPTIONS)
      .pipe(
        tap(() => {
          if (spin) {
            this.loaderService.hideLoader();
          }
        })
      );
    HTTP_OPTIONS.params = new HttpParams();
    return response;
  }

  getExternal<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(url);
  }

  put<T>(url: string, body?: any): Observable<T> {
    return this.httpClient.put<T>(BASE_API_URL + url, body, HTTP_OPTIONS);
  }

  post<T>(url: string, body?: any, spin: boolean = true): Observable<T> {
    if (spin) {
      this.loaderService.showLoader();
    }

    return this.httpClient.post<T>(BASE_API_URL + url, body, HTTP_OPTIONS).pipe(
      tap(() => {
        if (spin) {
          this.loaderService.hideLoader();
        }
      })
    );
  }

  async fetchFile(url: string): Promise<boolean> {
    const myRequest = new Request(url, {
      method: 'HEAD',
      cache: 'default'
    });

    return fetch(myRequest)
      .then(response => {
        if (response.ok && response.status === 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch(() => false);
  }
}
