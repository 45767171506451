import { UserViewModel } from './userViewModel';
import { TaskUser } from './taskUser';

export class TaskUserViewModel {
  id: string;
  first: string;
  last: string;
  fullName: string;

  constructor(user: TaskUser | UserViewModel) {
    this.id = user.id;
    this.first = user.first;
    this.last = user.last;
    this.fullName = `${user.first} ${user.last}`;
  }
}
