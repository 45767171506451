import { UserViewModel } from './userViewModel';

export class TaskCompleteDTO {
  // tslint:disable-next-line: variable-name
  employee_id?: string;
  // tslint:disable-next-line: variable-name
  employee_first?: string;
  // tslint:disable-next-line: variable-name
  employee_last?: string;
  completed: boolean;

  constructor(user: UserViewModel, completed: boolean) {
    this.employee_id = user.id;
    this.employee_first = user.first;
    this.employee_last = user.last;
    this.completed = completed;
  }
}
