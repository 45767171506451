export enum NeedToKnowDataFilters {
  AllContent = 'ALL CONTENT',
  SelectStoresOnly = 'SELECT STORES ONLY',
  AllStoresOnly = 'ALL STORES ONLY',
  FieldLeadershipOnly = 'FIELD LEADERSHIP ONLY'
}

export enum NeedToKnowDataFilterCssClasses {
  AllContent = 'all',
  SelectStoresOnly = 'select-stores',
  AllStoresOnly = 'all-stores',
  FieldLeadershipOnly = 'field-leadership'
}
