import { SvgIcon } from './svg-icon';
import { FileType, File } from './file';
import { MimeTypeUtility } from '@app/utilities/mime-utility';
import { DocumentFileType } from './document-file-type';

export class FileViewModel {
  id: number;
  title: string;
  mimeType: string;
  filename: string;
  depth: number;
  fileType: FileType;
  documentType: DocumentFileType;
  revision: number;
  icon: SvgIcon;
  url?: string;

  constructor(
    file: File,
    fileType: FileType = FileType.file,
    depth: number = 0
  ) {
    this.id = file.id;
    this.title = file.title;
    this.filename = file.filename;
    this.fileType = fileType;
    this.mimeType = file.mime_type;
    this.documentType = MimeTypeUtility.getDocumentFileTypeByMimeType(this.mimeType);
    this.icon = this.setIcon(this.documentType);

    this.depth = depth;
    this.revision = file.revision;
  }

  private setIcon(documentFileType: DocumentFileType): SvgIcon {
    switch (documentFileType) {
      case DocumentFileType.Video: {
        return new SvgIcon('video');
      }
      case DocumentFileType.Image: {
        return new SvgIcon('image');
      }
      default: {
        return new SvgIcon('alerts-document');
      }
    }
  }
}
