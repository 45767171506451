import { FileViewModel } from './file-view-model';
import { TakeActionTask } from './takeActionTask';
import { LinkViewModel } from './link-view-model';
import { TaskUserViewModel } from './taskUserViewModel';
import { FileType } from './file';

export class TaskViewModel {
  id: number;
  title: string;
  isCompleted: boolean;
  completedBy: TaskUserViewModel;
  allTasksComplete: boolean;
  link?: LinkViewModel;
  linkFiles?: FileViewModel[];
  linkAssets?: FileViewModel[];
  linkReports?: any;

  constructor(task: TakeActionTask, allTasksComplete: boolean) {
    this.id = task.id;
    this.title = task.title;
    this.isCompleted = task.completed;
    this.allTasksComplete = allTasksComplete;
    this.completedBy = task.completed_by
      ? new TaskUserViewModel(task.completed_by)
      : null;
    this.link = task.link ? new LinkViewModel(task.link) : null;
    this.linkFiles = task.link_reference.map(
      file => new FileViewModel(file, FileType.file)
    );
    this.linkAssets = task.link_assets.map(
      asset => new FileViewModel(asset, FileType.asset)
    );
  }
}
