import { DocumentFileType } from 'app/data/models/document-file-type';
import { FileExtensions } from 'app/data/models/file-extensions';

export class FileTypeUtility {
  public static getDocumentFileType(fileExtension: string): DocumentFileType {
    switch (fileExtension && fileExtension.toLowerCase()) {
      case FileExtensions.PDF:
        return DocumentFileType.PDF;
      case FileExtensions.MP4:
        return DocumentFileType.Video;
      case FileExtensions.MOV:
        return DocumentFileType.Video;
      case FileExtensions.PNG:
        return DocumentFileType.Image;
      case FileExtensions.JPEG:
        return DocumentFileType.Image;
      case FileExtensions.JPG:
        return DocumentFileType.Image;
      case FileExtensions.XLS:
        return DocumentFileType.Microsoft_Doc;
      case FileExtensions.XLSX:
        return DocumentFileType.Microsoft_Doc;
      case FileExtensions.PPT:
        return DocumentFileType.Microsoft_Doc;
      case FileExtensions.PPTX:
        return DocumentFileType.Microsoft_Doc;
      case FileExtensions.DOC:
        return DocumentFileType.Microsoft_Doc;
      case FileExtensions.DOCX:
        return DocumentFileType.Microsoft_Doc;
      default:
        return DocumentFileType.Other;
    }
  }
}
